import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./guards/auth/auth.guard";
import { NoAuthGuard } from "./guards/auth/no-auth.guard";
import { PerfilGuard } from "./guards/perfil/perfil.guard";
import { ActivationComponent } from "./components/pages/activation/activation.component";
import { ErrorComponent } from "./components/pages/error/error.component";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./components/pages/login/login.module").then(
        (m) => m.LoginModule
      ),
    canActivate: [NoAuthGuard],
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./components/pages/signup/signup.module").then(
        (m) => m.SignupModule
      ),
    canActivate: [NoAuthGuard],
  },
  {
    path: "reset-key",
    loadChildren: () =>
      import("./components/pages/reset-key/reset-key.module").then(
        (m) => m.ResetKeyModule
      ),
    canActivate: [NoAuthGuard],
  },
  {
    path: "reset-key-form",
    loadChildren: () =>
      import("./components/pages/reset-key-form/reset-key-form.module").then(
        (m) => m.ResetKeyFormModule
      ),
    canActivate: [NoAuthGuard],
  },
  {
    path: "home",
    loadChildren: () =>
      import("./components/pages/home/home.module").then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  
  {
    path: "creacion-ambientes",
    loadChildren: () =>
        import(
            "./components/pages/ambientes/creacion_ambientes/creacion-ambientes.module"
            ).then((m) => m.CreacionAmbientesModule),
    canActivate: [AuthGuard,PerfilGuard],
  },
  {
    path: "ambientes-reservados",
    loadChildren: () =>
        import(
            "./components/pages/ambientes/ambientes-reservados/ambientes-reservados.module"
            ).then((m) => m.AmbientesReservadosModule),
    canActivate: [AuthGuard,PerfilGuard],
  },
  {
    path: "destruccion-ambientes",
    loadChildren: () =>
        import(
            "./components/pages/ambientes/destruccion_ambientes/destruccion-ambientes.module"
            ).then((m) => m.AmbientesDestruccionModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
      path: "solicitud-ambientes",
    loadChildren: () =>
        import(
            "./components/pages/ambientes/solicitud-ambiente/solicitud-ambiente.module"
            ).then((m) => m.SolicitudAmbienteModule),
    canActivate: [AuthGuard],
  },
  {
    path: "servidores",
    loadChildren: () =>
      import("./components/pages/servidores/servidores.module").then(
        (m) => m.ServidoresModule
      ),
    
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "empresas/girha/girha-alertas",
    loadChildren: () =>
      import(
        "./components/pages/empresas/girha/girha-alertas/girha-alertas.module"
      ).then((m) => m.GirhaAlertasModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "lectores",
    loadChildren: () =>
      import("./components/pages/lectores/lectores.module").then(
        (m) => m.LectoresModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "lectores/modelos",
    loadChildren: () =>
        import("./components/pages/modelos-lectores/modelos-lectores.module").then(
            (m) => m.ModelosLectoresModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  { path: "activation", component: ActivationComponent },
  {
    path: "configuracion/lectores",
    loadChildren: () =>
      import(
        "./components/pages/configuracion/lectores-configuracion/lectores-configuracion.module"
      ).then((m) => m.LectoresConfiguracionModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "administracion",
    loadChildren: () =>
      import("./components/pages/administracion/administracion.module").then(
        (m) => m.AdministracionModule
      ),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "pendientes",
    loadChildren: () =>
      import("./components/pages/pendientes/pendientes.module").then(
        (m) => m.PendientesModule
      ),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "directorio",
    loadChildren: () =>
      import("./components/pages/directorio/directorio.module").then(
        (m) => m.DirectorioModule
      ),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "reporte-facturacion",
    loadChildren: () =>
        import("./components/pages/activos/reporte-facturacion/activos.module").then(
            (m) => m.ActivosModule
        ),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "catalogo-conceptos-facturacion",
    loadChildren: () =>
        import("./components/pages/activos/catalogo-conceptos-facturacion/conceptos-facturacion.module").then(
            (m) => m.ConceptosFacturacionModule
        ),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "lista-precios",
    loadChildren: () =>
        import("./components/pages/activos/lista-precios/lista-precios.module").then(
            (m) => m.ListaPreciosModule
        ),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "lista-precios-comercial",
    loadChildren: () =>
        import("./components/pages/crm/lista-precios-comercial/lista-precios-comercial.module").then(
            (m) => m.ListaPreciosComercialModule
        ),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "layout-sae",
    loadChildren: () =>
        import("./components/pages/activos/layout-sae/layout-sae.module").then((m) => m.LayoutSAEModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "clientes",
    loadChildren: () =>
        import("./components/pages/clientes/clientes.module").then((m) => m.ClientesModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "catalogos/productos",
    loadChildren: () =>
        import("./components/pages/catalogos/productos/productos.module").then((m) => m.ProductosModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "catalogos/vendedores",
    loadChildren: () =>
        import("./components/pages/catalogos/vendedores/vendedores.module").then((m) => m.VendedoresModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "catalogos/clientes",
    loadChildren: () =>
        import("./components/pages/catalogos/clientes/clientes.module").then((m) => m.ClientesModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "catalogos/solicitudes-ambientes",
    loadChildren: () =>
        import("./components/pages/catalogos/listado-solicitud-ambiente/listado-solicitud-ambiente.module").then((m) => m.ListadoSolicitudAmbienteModule),
    canActivate: [AuthGuard, PerfilGuard],
  },
  {
    path: "soportes-facturacion-auto",
    loadChildren: () =>
        import("./components/pages/activos/soporte-facturacion-auto/soporte-facturacion-auto.module").then((m) => m.SoporteFacturacionAutoModule),
    canActivate: [AuthGuard, PerfilGuard],
  },

  //administracion
  {
    path: "administracion/usuarios",
    loadChildren: () =>
      import("./components/pages/admin/usuarios/usuarios.module").then(
        (m) => m.UsuariosModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "administracion/roles-permisos",
    loadChildren: () =>
      import("./components/pages/admin/roles-permisos/roles-permisos.module").then(
        (m) => m.RolesPermisosModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "administracion/ventanas",
    loadChildren: () =>
      import("./components/pages/admin/ventanas/ventanas.module").then(
        (m) => m.VentanasModule
      ),
    canActivate: [AuthGuard],
  },

  // CRM
  {
    path: "crm/prospectos",
    loadChildren: () =>
        import("./components/pages/crm/prospectos/prospectos.module").then((m) => m.ProspectosModule),
    canActivate: [AuthGuard, PerfilGuard],
  },

  //Notificaciones
  {
    path: "administracion/control-notificaciones",
    loadChildren: () =>
        import("./components/pages/admin/control-notificaciones/control-notificaciones.module").then(
            (m) => m.ControlNotificacionesModule
        ),
    canActivate: [AuthGuard],
  },
  //Alertas Infra
  {
    path: "alertas-infra",
    loadChildren: () =>
      import("./components/pages/configuracion/alertas-infra/alertas-infra.module").then(
        (m) => m.AlertasInfraModule
      ),
    
    canActivate: [AuthGuard],
  },
  //Administracion de llaves ssh servidores
  {
    path: "administracion/administrar-credencial-servidores",
    loadChildren: () =>
      import("./components/pages/admin/admin-credencial-server/admin-credencial-server.module").then(
        (m) => m.AdminCredencialServerModule
      ),      
    
    canActivate: [AuthGuard],
  },
  {
    path: "catalogos/concentrado-tickets",
    loadChildren: () =>
        import("./components/pages/concentrado-tikets/concentrado-tickets.module").then(
            (m) => m.ConcentradoTicketsModule
        ),
    canActivate: [AuthGuard],
  },
  {
    path: "catalogos/listado-clientes",
    loadChildren: () =>
        import("./components/pages/catalogos/listado-clientes/listado-clientes.module").then(
            (m) => m.ListadoClientesModule
        ),
    canActivate: [AuthGuard],
  },
  {
    path: 'gestion-usuarios',
    loadChildren: ()=>
      import("./components/pages/gestion-usuarios/gestios-usuarios.module").then(
      (m)=> m.GestiosUsuariosModule
    ),
    canActivate: [AuthGuard],
  },
  { path: "error", component: ErrorComponent },
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "**", redirectTo: "error", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
