import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UsuarioModel} from '../models/usuario/usuario.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public url = 'https://megaro.back.girha.com/api/auth/'; //Servidor Produccion}
  // public url = 'http://127.0.0.1:8000/api/auth/'; //Desarollo

  userToken: string;
  expira: string;
  perfil: string;

  constructor(private http: HttpClient) { }


  login(usuario: UsuarioModel){
    const authData = {
      ...usuario
    };
    return this.http.post(
      `${this.url}login`,
      authData
    ).pipe(
      map(
        resp => {
          this.guardarToken(resp);
          return resp;
        }
      )
    );

  }

  signup(usuario: UsuarioModel){
    const authData = {
      ...usuario
    };

    const hola = this.http.post(
      `${this.url}signup`,
      authData
    ).pipe(
      map(
        resp => {
          return resp;
        }
      )
    );
        return hola;
  }

  logOut(){
    console.log(this.userToken);
    return this.http.get(
      `${this.url}logout`, {headers: {'Authorization': `Bearer ${this.userToken}`}})
      .pipe(
        map(
          resp => {
            if (resp['message'] === 'sesion_cerrada'){
              this.borrarToken();
            }
            return resp;
          }
        )
      );
  }

  resetKeyRequest(usuario: UsuarioModel){
    const authData = {
      ...usuario
    };
    return this.http.post(
      `${this.url}reset/inicia`,
      authData
    ).pipe(
      map(
        resp => {
          return resp;
        }
      )
    );

  }

  resetKey(usuario: UsuarioModel, token: string){
    const authData = {
      ...usuario,
      token:token
    };
    return this.http.post(
      `${this.url}reset/data`,
      authData
    ).pipe(
      map(
        resp => {
          return resp;
        }
      )
    );

  }

  user(){
    return this.http.get(
      `${this.url}user`, {headers: {'Authorization': `Bearer ${this.userToken}`}})
      .pipe(
        map(
          resp => {
            if (resp['message'] === 'sesion_cerrada'){
              console.log('entre');
              this.borrarToken();
            }
            return resp;
          }
        )
      );
  }

  private guardarToken(resp: any): void{
    this.userToken = resp['access_token'];
    this.expira = resp['expires_at'];
    this.perfil = resp['profile'];
    localStorage.setItem('token', this.userToken);
    localStorage.setItem('expira', this.expira);
    localStorage.setItem('perfil', this.perfil);
  }

  private borrarToken(): void{
    localStorage.removeItem('token');
    localStorage.removeItem('expira');
    localStorage.removeItem('perfil');
    localStorage.removeItem('user_id_auth');
  }

  leerToken(): string{
    if (localStorage.getItem('token')){
      this.userToken = localStorage.getItem('token');
    }else{
      this.userToken = '';
    }
    return this.userToken;
  }

  estaAutenticado(): boolean {
    this.leerToken();
    if ( this.userToken.length < 1 ) {
      return false;
    }
    const expira = Number(new Date(localStorage.getItem('expira')));
    const expiraDate = new Date();
    expiraDate.setTime(expira);
    return expiraDate > new Date();

  }

  public getUser() {
      return new Promise((resolve, reject) => {
        this.http
          .get(`${this.url}user`, {
            headers: { Authorization: `Bearer ${this.userToken}` },
          })
          .subscribe({
            next: (data) => resolve(data),
            error: (err) => reject(err),
          });
      });
    }
  
}
